import React from "react";

const NotFound = () => {
  return (
    <div>
      <h3>Es tut uns leid!</h3>
      <h4>Wir konnten die gesuchte Seite nicht finden.</h4>
    </div>
  );
};
NotFound.showHero = false;
export default NotFound;
